import React, { useEffect } from 'react'
import './About.scss'
import '../Header/Header.scss'
import img1 from '../../assets/img/about-section-2.jpeg'
import img2 from '../../assets/icon/phone-icon.png'

import card1 from '../../assets/img/photo_2024-12-19_20-34-13.jpg'
import card2 from '../../assets/img/photo_2024-12-19_20-34-23.jpg'
import card3 from '../../assets/slider/IMG_6092.jpeg'

import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTripadvisor } from "react-icons/fa";
import Card from '../Card/Card'
import icon1 from '../../assets/icon/icon-1.png'
import icon2 from '../../assets/icon/icon-2.png'
import icon3 from '../../assets/icon/icon-3.png'
import gifSlider from '../../assets/gifs/music-1.gif'
import pdfMenu from '../../assets/file/web_menu_210x297_compressed.pdf'
import AOS from 'aos';
import 'aos/dist/aos.css';
import bottomImage1 from '../../assets/photo_2025-02-10_23-33-01.png'
import Sliders from './components/Sliders/Sliders'
import ImagesBlock from './components/ImagesBlock/ImagesBlock'

const About = () => {
  const openPdfMenu = () => {
    window.open(pdfMenu, '_blank');
  };

  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <div className='about'>
      <div className='about-section-1'>
        <div className='about-section-1-title-container'>
          <h1 className='about-section-1-title' data-aos="fade-up"
            data-aos-offset="100"
            data-aos-easing="ease-in-sine" data-aos-duration="1000">Feel the taste<br /> of Tenerife</h1>
        </div>
        <div className='about-section-1-block'></div>
      </div>
      <div style={{ display: 'none' }}><a href="tel:+34822268012">822 268 012</a></div>
      <div className='about-section-2' data-aos="fade-up">
        <div className='about-section-2-img-container'>
          <img src={img1} alt="" className='about-section-2-img' />
        </div>
        <div className='about-section-2-info'>
          <p className='about-section-2-info-title-1'>Restaurant & Bar</p>
          <h2 className='about-section-2-info-title-2'>La Medusa &<br /> Restaurant</h2>
          <p className='about-section-2-info-desc'>This is not just a restaurant where you can eat delicious food. This is a place of beauty and enjoyment with a view of the Atlantic Ocean, a unique wine list to add new colors to the dishes. Also, every evening we invite artists who sing live music. The bartender makes the most original cocktails, and we also have a DJ every weekend.</p>
          <div className='about-section-2-info-line'></div>
          <div className='about-section-2-info-bottom'>
            <div className='about-section-2-info-bottom-block'>
              <p className='about-section-2-info-bottom-block-title'>EMAIL</p>
              <a className='about-section-2-info-bottom-block-email' href="mailto:lamedusarestaurants@gmail.com">lamedusarestaurants@gmail.com</a>
            </div>
            <div className='about-section-2-info-bottom-block'>
              <p className='about-section-2-info-bottom-block-title'>STAY TUNED</p>
              <div className='about-section-2-info-bottom-socials'>
                <a href="https://www.facebook.com/restaurantlamedusa" className='icon-container icon-container--dark'>
                  <FaFacebook />
                </a>
                <a href="https://www.instagram.com/la_medusa_tenerife/" className='icon-container icon-container--dark'>
                  <FaInstagram />
                </a>
                <a href="https://www.tripadvisor.com/Restaurant_Review-g187479-d20257413-Reviews-Restaurant_La_Medusa_Cocktail_Bar-Tenerife_Canary_Islands.html" className='icon-container icon-container--dark'>
                  <FaTripadvisor />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Sliders />
      <ImagesBlock />
      <div className='about-section-3' data-aos="fade-down">
        <div className='about-section-3-content'>
          <p className='about-section-3-title'>Reserve</p>
          <div>
            <a href="tel:+34603839509"> <img src={img2} alt="phone" className='about-section-3-icon' /></a>
          </div>
          <div className='about-section-3-info'>
            <a className='about-section-3-info-call' href="tel:+34603839509">Call</a>
            <a className='about-section-3-info-call' href="tel:+34603839509">+34 603 83 95 09</a>
          </div>
        </div>
      </div>
      <div className='about-section-4' data-aos="fade-up" >
        <div className='about-section-4-content'>
          <p className='about-section-4-paragraph-1'>FACILITIES & MORE</p>
          <h2 className='about-section-4-title'>Services</h2>
          <p className='about-section-4-paragraph-2'>
            We care about each of our guests. Quality service is our main mission.<br />
            We have our own wine list and live music every night.
          </p>
          <div>
            <div className='about-section-4-btn-container'>
              <a className='about-section-4-button about-section-4-button--green' href="tel:+34603839509">REQUEST NOW</a>
              <a className='about-section-4-button about-section-4-button--white' href="tel:+34603839509">CONTACT US</a>
            </div>
          </div>
        </div>
      </div>
      <div className='about-section-5'>
        <div className='about-section-5-content'>
          <div className='about-section-5-top'>
            <Card
              img={card1}
              title="PAELLA MIXTA"
              price="25"
              option1="Generous shrimp"
              option2="Seasoned with lime"
              btn="View Menu"
              color="green"
              onClick={openPdfMenu}
              menu={true}
            />
            <Card
              img={card2}
              title="CHEESE BAKED LOBSTER"
              price="12,5"
              option1="Delicately baked lobster"
              option2="Melted cheese topping"
              btn="Book now"
              color="white"
              small="per 100g"
              text="white"
              back="green"
            />
            <Card
              img={card3}
              title="SALMON TARTARE"
              price="19"
              option1="Freshly diced salmon"
              option2="Tartare sauce"
              btn="Reservation"
              color="green"
            />
          </div>
          <div className='about-section-5-bottom'>
            <div className='about-section-5-bottom-container'>
              <div className='about-section-5-block' data-aos="zoom-in-up">
                <p className='about-section-5-num'>01</p>
                <div className='about-section-5-line'></div>
                <p className='about-section-5-title'>MUSIC</p>
                <p className='about-section-5-paragraph'>Our restaurant has live music 7 days a week with different artists</p>
              </div>
              <div className='about-section-5-block' data-aos="zoom-in-down">
                <p className='about-section-5-num'>02</p>
                <div className='about-section-5-line'></div>
                <p className='about-section-5-title'>BAR</p>
                <p className='about-section-5-paragraph'>Our bartenders make the best cocktails in all of Tenerife</p>
              </div>
            </div>
            <div className='about-section-5-bottom-container' data-aos="zoom-in-up">
              <div className='about-section-5-block'>
                <p className='about-section-5-num'>03</p>
                <div className='about-section-5-line'></div>
                <p className='about-section-5-title'>Menu</p>
                <p className='about-section-5-paragraph'>Our menu has a choice of any dishes, not only seafood but also good meat</p>
              </div>
              <div className='about-section-5-block' data-aos="zoom-in-down">
                <p className='about-section-5-num'>04</p>
                <div className='about-section-5-line'></div>
                <p className='about-section-5-title'>LOCATION</p>
                <p className='about-section-5-paragraph'>We are located on the very shore of the Ocean with a beautiful view</p>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className='about-section-6'>
        <div className='about-section-6-content'>
          <p className='about-section-6-title' data-aos="fade-down">LA MEDUSA</p>
          <p className='about-section-6-meal' data-aos="fade-down">Steak New York</p>
          <div className='about-section-6-second-content'>
            <div className='about-section-6-icon-block'>
              <img src={icon1} alt="icon" className='about-section-6-icon' />
              <p className='about-section-6-icon-title'>Dinner</p>
            </div>
            <div className='about-section-6-icon-block about-section-6-icon-block--second'>
              <img src={icon2} alt="icon" className='about-section-6-icon' />
              <p className='about-section-6-icon-title'>Breakfast</p>
            </div>
            <div className='about-section-6-icon-block'>
              <img src={icon3} alt="icon" className='about-section-6-icon' />
              <p className='about-section-6-icon-title'>Lunch</p>
            </div>
          </div>
        </div>
      </div>
      <div className='about-section-7'>
        <div className='about-section-7-content'>
          <h2 className='about-section-7-main-title'>We Offer</h2>
          <div className='about-section-7-container'>
            <div className='about-section-7-block' data-aos="fade-up">
              <img src={bottomImage1} alt="Cocktails" className='about-section-7-img' />
              <h3 className='about-section-7-title'>Happy Hours</h3>
              <p className='about-section-7-desc'>
                We have -20% discount during happy hours 14:30-17:30 for all food menu on the 10-16 of February
              </p>
            </div>

            <div className='about-section-7-block' data-aos="fade-up">
              <img src={gifSlider} alt="Music" className='about-section-7-img' />
              <h3 className='about-section-7-title'>Music</h3>
              <p className='about-section-7-desc'>Live music with different artists</p>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default About
